import React from "react"
import { graphql } from "gatsby"

import Layout from "../../../components/layout/layout"
import SEO from "../../../components/seo"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import styled from "styled-components"
import { device } from '../../../components/device'

import Sidebar from "../../../components/sidebar/sidebar"
import Banner from "../../../components/vinhos/vinhosBanner"
import Vinhos from "../../../components/vinhos/vinhosCardList"
import Footer from "../../../components/footer/footer"
import SidebarMobile from '../../../components/sidebar/mobile'
import WineFilter from '../../../components/sidebar/wine-filter'

const AzulPortugalPage = ({data}) => {
  const breakpoints = useBreakpoint();

  // const [types] = useState(getTypes());
  // const [filtrados, setFiltrados] = useState(data.vinhosJson.docs.bairrada);

  let listaVinhos = [];
  listaVinhos = listaVinhos.concat(data.vinhosJson.docs.bairrada, data.vinhosJson.docs.dao, data.vinhosJson.docs.douro, data.vinhosJson.docs.setubal, data.vinhosJson.docs.palmela, data.vinhosJson.docs.porto, data.vinhosJson.docs.tejo, data.vinhosJson.docs.verde, data.vinhosJson.regionais.alentejo, data.vinhosJson.regionais.mesa, data.vinhosJson.regionais.minho, data.vinhosJson.regionais.setubal, data.vinhosJson.regionais.tejo);

  let vinhosFiltrados = []
  vinhosFiltrados = listaVinhos.filter(item => {
    return item.clienteOrigem.match('azul-portugal');
  })

  // function changeSelection(selec) {
  //   if(selec==="none"){
  //     setFiltrados(data.vinhosJson.docs.bairrada);  
  //   }
  //   else{
  //     setFiltrados(data.vinhosJson.docs.bairrada.filter(data => data.tipo===selec));
  //   }
  // }

  // function getTypes(){
  //   var typeTemp={
  //     vb: false,
  //     vt: false,
  //     vr: false,
  //     vl: false,
  //     es: false,
  //     vp: false
  //   }
  //   data.vinhosJson.docs.bairrada.forEach(vinho => {
  //     switch(vinho.tipo){
  //       case "Vinho Branco":
  //         typeTemp.vb = true;
  //         break;
  //       case "Vinho Tinto":
  //         typeTemp.vt = true;
  //         break;
  //       case "Vinho Rosé":
  //         typeTemp.vr = true;
  //         break;
  //       case "Vinho Licoroso":
  //         typeTemp.vl = true;
  //         break;
  //       case "Espumante":
  //         typeTemp.es = true;
  //         break;
  //       case "Vinho do Porto":
  //         typeTemp.vp = true;
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  //   return(typeTemp);
  // }

  return (
    <Layout color="#150e04">
      <SEO title="Vinhos Azul Portugal" />
      {!breakpoints.mobile ?
        <Sidebar switchMenu="right" menuBtn/>
      :
        <SidebarMobile brown/>
      }
      {breakpoints.mobile &&
        <WineFilter /*changeFilter={changeSelection} types={types} */ white="isWhite"/>
      }
      <Spacing>
        <Banner data={data.vinhosJson.banners.docs.azulportugal} vinho='azul-portugal' /*changeFilter={changeSelection} types={types}*/ opacity/>
        <Vinhos data={vinhosFiltrados} full={true}/>
      </Spacing>
      <Footer/>
    </Layout>
  )
}

export default AzulPortugalPage

const Spacing = styled.div`
  position:relative;

  @media ${device.desktop}{
    margin-left: 200px;
    margin-right: 14px;
  }

  @media ${device.desktopL}{
    margin-left: 15.65%;
  }
`


export const Json = graphql`
  query vinhosAzulPortugal {
    vinhosJson{
      banners{
        docs{
          azulportugal{
            banner{
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      docs{
        bairrada{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          clienteOrigem
          ano
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        dao{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          clienteOrigem
          ano
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        tejo{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          clienteOrigem
          ano
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        douro{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          clienteOrigem
          ano
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        setubal{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          clienteOrigem
          ano
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        palmela{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          clienteOrigem
          ano
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        porto{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          clienteOrigem
          ano
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        verde{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          clienteOrigem
          ano
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
      }
      regionais{
        tejo{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          clienteOrigem
          ano
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        alentejo{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          clienteOrigem
          ano
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        mesa{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          clienteOrigem
          ano
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        minho{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          clienteOrigem
          ano
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        setubal{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          clienteOrigem
          ano
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
      } 
    }
  }
`